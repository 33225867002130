var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    class: _vm.device + "-event",
    attrs: {
      id: "content"
    }
  }, [_c("div", {
    staticClass: "aboutBannerBox"
  }, [_vm.language == 1 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/banners_ir_cn.jpg")
    }
  }) : _vm._e(), _vm.language == 2 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/banners_ir_en.jpg")
    }
  }) : _vm._e(), _vm.language == 3 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/banners_ir_cn.jpg")
    }
  }) : _vm._e()]), _c("div", {
    staticClass: "companyBlock",
    staticStyle: {
      "padding-bottom": "0"
    }
  }, [_c("div", {
    staticClass: "companyBlock-about"
  }, [_c("div", {
    staticClass: "companyBlock-hd"
  }, [_vm.language == 1 ? _c("h2", [_vm._v("投资者关系")]) : _vm._e(), _vm.language == 2 ? _c("h2", [_vm._v("INVESTORS")]) : _vm._e(), _vm.language == 3 ? _c("h2", [_vm._v("投資者關係")]) : _vm._e()]), _c("div", {
    staticClass: "companyInfo clearfix"
  }, [_c("ul", {
    staticClass: "tMenuLeft",
    class: _vm.language == 2 ? "tMenuLeftEn" : ""
  }, [_vm._l(_vm.irMenuData, function (items, index) {
    return _vm.language == 1 || !_vm.language ? _c("router-link", {
      attrs: {
        to: items.path
      }
    }, [_c("li", {
      class: _vm.activeMenu == items.value ? "active" : ""
    }, [_vm._v(_vm._s(items.name)), _c("em", [_vm._v(">")])])]) : _vm._e();
  }), _vm._l(_vm.irMenuData, function (items, index) {
    return _vm.language == 2 ? _c("router-link", {
      attrs: {
        to: items.path
      }
    }, [_c("li", {
      class: _vm.activeMenu == items.value ? "active" : ""
    }, [_vm._v(_vm._s(items.nameEn)), _c("em", [_vm._v(">")])])]) : _vm._e();
  }), _vm._l(_vm.irMenuData, function (items, index) {
    return _vm.language == 3 ? _c("router-link", {
      attrs: {
        to: items.path
      }
    }, [_c("li", {
      class: _vm.activeMenu == items.value ? "active" : ""
    }, [_vm._v(_vm._s(items.nameTr)), _c("em", [_vm._v(">")])])]) : _vm._e();
  })], 2), _c("div", {
    staticClass: "comContentRight",
    class: _vm.language == 2 ? "comContentRightEn" : "",
    staticStyle: {
      "padding-bottom": "10em"
    }
  }, [_c("div", {
    staticClass: "govDeteail"
  }, [_vm.language == 1 ? _c("h4", [_vm._v("董事会")]) : _vm._e(), _vm.language == 2 ? _c("h4", [_vm._v("BOARD OF DIRECTORS")]) : _vm._e(), _vm.language == 3 ? _c("h4", [_vm._v("董事會")]) : _vm._e(), _c("ul", {
    attrs: {
      id: "dongshUrl"
    }
  }, _vm._l(_vm.dshFile, function (item, index) {
    return _c("a", {
      key: index,
      attrs: {
        href: _vm.title + item.fileSrc,
        target: "_blank"
      }
    }, [_c("li", [_c("p", [_vm._v(_vm._s(item.fileName))]), _c("span")])]);
  }), 0)]), _c("div", {
    staticClass: "govDeteail",
    class: _vm.language == 2 ? "govDeteail14" : ""
  }, [_vm.language == 1 ? _c("h4", [_vm._v("委员会")]) : _vm._e(), _vm.language == 2 ? _c("h4", [_vm._v("COMMITTEES")]) : _vm._e(), _vm.language == 3 ? _c("h4", [_vm._v("委員會")]) : _vm._e(), _c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("dl", [_vm.language == 1 ? _c("dt", [_vm._v("审核委员会")]) : _vm._e(), _vm.language == 2 ? _c("dt", [_vm._v("Audit Committee")]) : _vm._e(), _vm.language == 3 ? _c("dt", [_vm._v("審核委員會")]) : _vm._e(), _c("dd", {
    attrs: {
      id: "shenhe"
    },
    domProps: {
      innerHTML: _vm._s(_vm.people.shenhe)
    }
  }), _c("dd", {
    attrs: {
      id: "shenheUrl"
    }
  }, _vm._l(_vm.shFile, function (item, index) {
    return _c("a", {
      key: index,
      attrs: {
        href: _vm.title + item.fileSrc,
        target: "_blank"
      }
    }, [_vm._v(_vm._s(item.fileName)), _c("span")]);
  }), 0)]), _c("dl", [_vm.language == 1 ? _c("dt", [_vm._v("薪酬委员会")]) : _vm._e(), _vm.language == 2 ? _c("dt", [_vm._v("Remuneration Committee")]) : _vm._e(), _vm.language == 3 ? _c("dt", [_vm._v("薪酬委員會")]) : _vm._e(), _c("dd", {
    attrs: {
      id: "xinchou"
    },
    domProps: {
      innerHTML: _vm._s(_vm.people.xinchou)
    }
  }), _c("dd", {
    attrs: {
      id: "xinchouUrl"
    }
  }, _vm._l(_vm.xcFile, function (item, index) {
    return _c("a", {
      key: index,
      attrs: {
        href: _vm.title + item.fileSrc,
        target: "_blank"
      }
    }, [_vm._v(_vm._s(item.fileName)), _c("span")]);
  }), 0)]), _c("dl", [_vm.language == 1 ? _c("dt", [_vm._v("提名委员会")]) : _vm._e(), _vm.language == 2 ? _c("dt", [_vm._v("Nomination Committee")]) : _vm._e(), _vm.language == 3 ? _c("dt", [_vm._v("提名委員會")]) : _vm._e(), _c("dd", {
    attrs: {
      id: "timing"
    },
    domProps: {
      innerHTML: _vm._s(_vm.people.timing)
    }
  }), _c("dd", {
    attrs: {
      id: "timingUrl"
    }
  }, _vm._l(_vm.tmFile, function (item, index) {
    return _c("a", {
      key: index,
      attrs: {
        href: _vm.title + item.fileSrc,
        target: "_blank"
      }
    }, [_vm._v(_vm._s(item.fileName)), _c("span")]);
  }), 0)])])]), _c("el-divider"), _c("div", {
    staticClass: "govDeteail"
  }, [_vm.language == 1 ? _c("h4", [_vm._v("其他")]) : _vm._e(), _vm.language == 2 ? _c("h4", [_vm._v("OTHERS")]) : _vm._e(), _vm.language == 3 ? _c("h4", [_vm._v("其他")]) : _vm._e(), _c("ul", {
    attrs: {
      id: "otherFile"
    }
  }, _vm._l(_vm.otherFile, function (item, index) {
    return _c("a", {
      key: index,
      attrs: {
        href: _vm.title + item.fileSrc,
        target: "_blank"
      }
    }, [_c("li", [_c("p", [_vm._v(_vm._s(item.fileName))]), _c("span")])]);
  }), 0)])], 1)])])])]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };