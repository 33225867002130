import { mapState } from 'vuex';
export default {
  name: '',

  data() {
    return {
      title: this.$api.api_title,
      people: {},
      dshFile: [],
      shFile: [],
      xcFile: [],
      tmFile: [],
      otherFile: []
    };
  },

  computed: {
    activeMenu() {
      const route = this.$route;
      return route.name;
    },

    ...mapState({
      irMenuData: state => state.irMenuData,
      device: state => state.device,
      language: state => state.language
    })
  },
  watch: {},

  created() {
    this.getData();
  },

  mounted() {},

  methods: {
    getData() {
      this.$http.post(this.$api.investment_postManagerPersonSearch).then(res => {
        if (res.code == 0) {
          if (res.data) {
            if (this.$store.state.language == 2) {
              this.people = res.data.en;
            } else if (this.$store.state.language == 3) {
              this.people = res.data.traditional;
            } else if (this.$store.state.language == 1) {
              this.people = res.data.cn;
              console.log(this.offerList);
            }
          }
        }
      });
      this.$http.post(this.$api.investment_postManagerFileSearch).then(res => {
        if (res.code == 0) {
          if (res.data) {
            if (this.$store.state.language == 2) {
              this.dshFile = res.data.enDongshiFile;
              this.shFile = res.data.enShenHeFile;
              this.xcFile = res.data.enXinChouFile;
              this.tmFile = res.data.enTimingFile;
              this.otherFile = res.data.enOtherFile;
            } else if (this.$store.state.language == 3) {
              this.dshFile = res.data.traDongshiFile;
              this.shFile = res.data.traShenHeFile;
              this.xcFile = res.data.traXinChouFile;
              this.tmFile = res.data.traTimingFile;
              this.otherFile = res.data.traOtherFile;
            } else if (this.$store.state.language == 1) {
              this.dshFile = res.data.cnDongshiFile;
              this.shFile = res.data.cnShenHeFile;
              this.xcFile = res.data.cnXinChouFile;
              this.tmFile = res.data.cnTimingFile;
              this.otherFile = res.data.cnOtherFile;
            }
          }
        }
      });
    }

  }
};